import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import { LABELS_SP62_API } from '../../utils/const'
import Table from '../../components/Table'
import { useTranslation } from 'react-i18next'
import { group } from 'd3-array'

const SP62Table = ({ regions }) => {
  const { t } = useTranslation()
  // const allYears = []
  // let years
  // const wrangleData = () => {
  //   const allData = Object.entries(regions)
  //     .map(([key, value]) => {
  //       const keysToExclude = [
  //         'memberState',
  //         'iSO3',
  //         'id',
  //         'region',
  //         'incomeGroup',
  //         'uNICEFRegion'
  //       ]

  //       if (!keysToExclude.includes(key) && value !== null) {
  //         const years = key.slice(-8)
  //         const year1 = years.slice(0, 4)
  //         const year2 = years.slice(-4)
  //         const yearWithHyphen = `${year1}-${year2}`
  //         let category
  //         if (key.includes('decline')) {
  //           category = 1
  //         }
  //         if (key.includes('stable')) {
  //           category = 2
  //         }

  //         if (key.includes('increase')) {
  //           category = 3
  //         }

  //         allYears.push(yearWithHyphen)
  //         return { year: yearWithHyphen, category, value }
  //       } else return null
  //     })

  //     .filter((item) => item)

  //   years = Array.from(new Set(allYears))
  //   const totalsObj = {}

  //   years.forEach((year) => {
  //     const total = allData
  //       .filter((d) => d.year === year)
  //       .reduce((acc, d) => {
  //         return acc + d.value
  //       }, 0)

  //     totalsObj[year] = total
  //   })

  //   const formattedData = [...Array.from({ length: 3 })].map((_, i) => {
  //     const category = i + 1
  //     const catData = allData
  //       .filter((d) => d.category === category)
  //       .reduce((acc, d) => {
  //         acc[d.year] = `${((d.value / totalsObj[d.year]) * 100).toFixed(1)}%`
  //         return acc
  //       }, {})
  //     return Object.assign(
  //       {
  //         category: t(LABELS_SP62[category])
  //       },
  //       catData
  //     )
  //   })

  //   return formattedData
  // }

  //const sp62Data = wrangleData()
  const BASELINE_YEAR = '2018-2019'
  let yrs = []
  const wrangle62Data = (data) => {
    const dataMap = group(data, (d) => d.DIMENSION2)
    const dataByCategory = Array.from(dataMap.values())
    return dataByCategory
      .map((arr) => {
        return arr.reduce((acc, d) => {
          if (d.YEAR_RANGE !== BASELINE_YEAR) yrs.push(d.YEAR_RANGE ?? d.YEAR)
          acc[
            d.YEAR_RANGE === BASELINE_YEAR ? 'Baseline' : d.YEAR_RANGE ?? d.YEAR
          ] = `${d.VALUE_NUM.toFixed()}%`
          acc['category'] = t(LABELS_SP62_API[d.DIMENSION2])
          acc['group'] = d.DIMENSION2
          return acc
        }, {})
      })
      .sort((a, b) => (a.group === 'Increased' ? 1 : -1))
      .sort((a, b) => (a.group === 'Decreased' ? -1 : 1))
  }

  const sp62Data = wrangle62Data(regions)
  const years = Array.from(new Set(yrs))
  const data = React.useMemo(() => sp62Data, [sp62Data])

  const yearHeaders = years?.map((year) => {
    return {
      Header: `${year}`,
      accessor: `${year}`
    }
  })
  const columns = React.useMemo(() => {
    const baseHeader = [
      {
        Header: '',
        accessor: 'category' // accessor is the "key" in the data
      },
      {
        Header: `${t('Baseline')} (${BASELINE_YEAR})`,
        accessor: 'Baseline' // accessor is the "key" in the data
      }
    ]
    return baseHeader.concat(yearHeaders)
  }, [yearHeaders, t])

  return (
    <Table
      data={data}
      columns={columns}
      noBoldTopRow={true}
      description={t(
        'A table that lists the proportion of countries whose domestic government and donor expenditure on primary health care increased or remained stable.'
      )}
    />
  )
}

export default SP62Table
